body {
  margin-top: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  user-select: none;
  -moz-user-select: none;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.knob-container {
  display: grid;
  justify-content: center;
  align-items: center;
  margin: 0.625rem;
  margin-bottom: 3rem;
}

.knob {
  display: flex;
  position: relative;
}
.knob .ticks {
  position: absolute;
}
.knob .ticks .tick {
  position: absolute;
  background: black;
  box-shadow: inset 0 0 0 0 black;
  width: 3px;
  transition: box-shadow 0.5s;
}
.knob .ticks .tick.active {
  box-shadow: inset 0 0 5px 2px #509eec, 0 0 0 1px #369;
}
.knob.outer {
  border-radius: 50%;
  border: 1px solid #222;
  border-bottom: 5px solid #222;
  background-image: radial-gradient(closest-side, 100% 70%, #666 6%, #333 90%);
  box-shadow: 0 5px 15px 2px black, 0 0 5px 3px black, 0 0 0 12px #444;
}
.knob.inner {
  border-radius: 50%;
}
.knob.inner .grip {
  position: absolute;
  width: 5%;
  height: 5%;
  bottom: 2%;
  left: 50%;
  transform: translateX(-50%);
  border-radius: 50%;
  background: #509eec;
  box-shadow: 0 0 3px 1px black;
}

.gauge {
  width: 100%;
  max-width: 6.25rem;
  min-width: 6.25rem;
  margin-bottom: 0.625rem;
  font-size: 0.85rem;
  color: rgb(17, 47, 71);
}

.gauge__body {
  width: 100%;
  height: 0;
  padding-bottom: 50%;
  background: rgb(224, 222, 222);
  position: relative;
  border-top-left-radius: 100% 200%;
  border-top-right-radius: 100% 200%;
  overflow: hidden;
}

.gauge__fill {
  position: absolute;
  top: 100%;
  left: 0;
  width: inherit;
  height: 100%;
  background-color: rgb(92, 130, 130);
  transform-origin: center top;
  transform: rotate(0.25turn);
  transition: transform 0.2s ease-out;
}

.gauge__cover {
  width: 75%;
  height: 150%;
  background-color: rgb(205, 198, 198);
  border-radius: 50%;
  position: absolute;
  top: 25%;
  left: 50%;
  transform: translateX(-50%);

  color: black;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-bottom: 25%;
  box-sizing: border-box;
}

.logo {
  position: relative;
  height: 28.125rem;
  width: 6.25rem;
}

.bar {
  z-index: 1;
  height: 21.875rem;
  width: 2.5rem;
  background-color: aquamarine;
  border-radius: 1.563rem 1.563rem 0 0;
  position: absolute;
  top: 0.313rem;
  left: 50%;
  transform: translateX(-50%);
}

.circle {
  background-color: aquamarine;
  height: 6.25rem;
  width: 6.25rem;
  position: absolute;
  bottom: 0;
  border-radius: 50%;
}

.circle::after {
  content: "";
  display: block;
  width: 3.75rem;
  height: 3.75rem;
  border-radius: 50%;
  background-color: red;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.bar::after {
  content: "";
  display: block;
  height: 22.5rem;
  width: 1.25rem;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  bottom: -22px;
  border-radius: 0.625rem 0.625rem 0 0;
  background-color: red;
  animation: anim 3s linear infinite;
}

@keyframes anim {
  0% {
    height: 5%;
  }
  70% {
    height: 100%;
  }
  100% {
    height: 5%;
  }
}

.react-resizable {
  position: relative;
}
.react-resizable-handle {
  position: absolute;
  width: 20px;
  height: 20px;
  bottom: 0;
  right: 0;
  border: solid black;
  border-width: 0 3px 3px 0;
  display: inline-block;
  padding: 3px;
  background-position: bottom right;
  padding: 0 3px 3px 0;
  background-repeat: no-repeat;
  background-origin: content-box;
  box-sizing: border-box;
  cursor: se-resize;
}
